export const environment = {
  Wp: false,
  production: true,
  apiUrl: 'https://fbs.wcs-servizi.it/api',
  bbbUrl: 'https://newsbs.ddns.net/bigbluebutton',
  langPrefix: './assets/i18n/',
  langSuffix: '_Progetti.json',
  loginbgPath: 'assets/images/background/teamwork-3213924.jpeg',
  icon:'assets/images/KlimaIMGxicon.png',
  baseUrl: '/task/calendario',
  href: '/Login',
  homepages: [
    // {Ruoli: ["AC"], link: "/Cliente/profile"}
  ],
  logoPath: 'assets/images/wcs.jpeg',
  loginMsg: '',
  logoBg: 'skin6', // six possible values: skin(1/2/3/4/5/6),
  navbarbg: 'skin2', // six possible values: skin(1/2/3/4/5/6)
  sidebarbg: 'skin1', // six possible values: skin(1/2/3/4/5/6)
  userCircleColor: "",
  theme: 'dark',
  dir: 'ltr', // two possible values: ltr, rtl
  layout: 'vertical', // fixed value. shouldn't be changed.
  sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
  sidebarpos: 'fixed', // two possible values: fixed, absolute
  headerpos: 'fixed', // two possible values: fixed, absolute
  boxed: 'full', // two possible values: full, boxed
  ClientiType: ['C', 'F'],
  IDTecnico: "RT",
  //#region Moduli
  Modules:{
    "twoFactorAuth": true,
    "Admin": true,
    "Personale": true,
    "Clienti": true,
    "Interventi": true,
    "Prima_Nota": false,
    "Listini": false,
    "Progetti": true,
    "Prodotti": true,
    "Documenti": true,
    "Scadenzario": false,
    "Spedizioni": false,
    "Stampe": false,
    "Task": true,
    "Shared": true,
    "Dashboard": false,
    "Settings": true,
    "Formazione": true,
    "VersioneCarbotti": false,
    "PartiProdotto": false,
    "Parti": false,
    "Rma": true,
    "Ecommerce": false,
    "ScadenzeFiglie": false,
    "Registrazione": false,
    "Ticket": true,
    "Acquisti": false,
    "taskboard": true,
    "Camera":false,
    "Valutazioni":false
  },
  //#endregion
  //#region Addon
  TaskAddon: {
    checkList : true,
  },
  Personale:{},
  ProgettiAddon: {
    commerciale: false,
    segnalatore: false,
    categoriaVoceDiCosto: false,
    CampiOfferta: [
      {
        modificabile: true,
        nome: 'Offerta.Responsabile',
        oggetti: ['manager'],
        inputType: 'singleDropdown',
        source: 'managers',
        internalOutput: 'managerObject',
        OnFilter: 'filtroManager',
        OnChange: 'OnManagerChange',
        placeholder: 'Seleziona',
        settings: {
          singleSelection: true,
          closeDropDownOnSelection: true,
          singleOutput: true,
          idField: 'id',
          textField: 'fullName',
          searchPlaceholderText: "inserire almeno 3 caratteri",
          allowSearchFilter: true,
          allowRemoteDataSearch: true,
        },
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'cliente.commerciale',
        oggetti: ['commerciale'],
        inputType: 'singleDropdown',
        source: 'managers',
        internalOutput: 'commerciale',
        OnFilter: 'filtroCommerciale',
        placeholder: 'Seleziona',
        settings: {
          singleSelection: true,
          closeDropDownOnSelection: true,
          singleOutput: true,
          idField: 'id',
          textField: 'fullName',
          searchPlaceholderText: "inserire almeno 3 caratteri",
          allowSearchFilter: true,
          allowRemoteDataSearch: true,
        },
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'cliente.segnalatore',
        oggetti: ['clienti'],
        inputType: 'singleDropdown',
        source: 'clienti',
        internalOutput: 'segnalatore',
        OnFilter: 'filtrosegnalatore',
        placeholder: 'Seleziona',
        settings: {
          singleSelection: true,
          closeDropDownOnSelection: true,
          singleOutput: true,
          idField: 'id',
          textField: 'nominativo',
          searchPlaceholderText: "inserire almeno 3 caratteri",
          allowSearchFilter: true,
          allowRemoteDataSearch: true,
          noDataAvailablePlaceholderText: "Nessun Cliente trovato ",
          moreButton: true,
          moreButtonText: "Cerca in AdHoc",
        },
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Colore',
        oggetti: ['colore'],
        inputType: 'color',
        titleClass: 'control-label col-form-label',
        class: 'col-lg-1',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Lavorato',
        oggetti: ['lavorato'],
        inputType: 'text',
        titleClass: 'control-label col-form-label',
        class: 'col-lg-1',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Oggetto:',
        oggetti: ['oggetto'],
        inputType: 'text',
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Numero Ordine:',
        oggetti: ['codice'],
        inputType: 'text',
        titleClass: 'control-label col-form-label',
        class: 'col-lg-2',
        placeholder: 'Numero Ordine:',
        visibleifProhibited: true
      },
      {
        prevDivClass: "col-md-12",
        modificabile: true,
        nome: 'Offerta.Creazione',
        oggetti: ['data_creazione'],
        inputType: 'date',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Offerta.Offerta',
        oggetti: ['data_emissione'],
        inputType: 'date',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Offerta.Accettazione',
        oggetti: ['data_accettazione'],
        inputType: 'date',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Offerta.Conferma',
        oggetti: ['data_conferma'],
        inputType: 'date',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Note',
        oggetti: ['note'],
        inputType: 'textarea',
        titleClass: 'control-label col-form-label',
        class: 'col-md-6',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Note Private',
        oggetti: ['note_private'],
        inputType: 'textarea',
        titleClass: 'control-label col-form-label',
        class: 'col-md-6',
        visibleifProhibited: false
      },
      //   {
      //   modificabile:true,
      //   nome: 'Gestore',
      //   oggetti: ['manager'],
      //   inputType: 'singleDropdown',
      //   source: 'managers',
      //   internalOutput: 'gestoreObject',
      //   OnFilter: 'filtroCommerciale',
      //   placeholder: 'Seleziona',
      //   settings: {
      //     singleSelection: true,
      //     closeDropDownOnSelection: true,
      //     singleOutput:true,
      //     idField: 'id',
      //     textField: 'fullName',
      //     searchPlaceholderText: "inserire almeno 3 caratteri",
      //     allowSearchFilter: true,
      //     allowRemoteDataSearch: true,
      //   },
      //   titleClass: 'control-label col-form-label'  ,
      //   class: 'col-lg-3',
      //   visibleifProhibited: true
      //
      // },
    ],
    CampiSottoOfferta: []
  },
  ClientiAddOn: {
    codAdhoc:false,
    enable:false,
    info:true,
    commerciale: true,
    segnalatore: true,
    segnalatoreInterno: true,
    sedi: true,
    TipoSedePrimaria: false,
    attivita: true,
    geolocalizzazione: false,
    ruolo: true,
    Modifica: false,
    prodotti: true,
    rma: true,
    accesso: false,
    referenti: false,
    interventi: true,
    note_private:false,
    rating : false,
    ruoliAccesso:false,
    compilazione:false,
    luogoNascita: false,
    dataNascita: false,
    statoCivile:false,
    titoloStudio: false,
    occupazione: false,
    invalidita: false,
    legge104: false,
    diagnosi: false,
    accompagnamento:false,
    pensione: false,
    tutelare: false,
    medico: false,
    telMedico: false,
    terapia:false
  },
  ProdottiAddOn: {
    sottoTitolo: false,
    standby: false,
    tipi:false, // tipi di prodotto (1 - 2ante klima)
    shortDescription: false,
    classeEnergetica: false,
    tags: false,
    video: false,
    dettaglio:false,
    files: true,
    cart: false,
    codice: false,
    codice_produttore: false,
    tipo: true,
    cat_image: true,
    lista_prezzo:true,
    TranslatedDesc: false,
    codAdhoc:false,
    DragSort: false,
    cat_Sort: false
  },
  legenda:[],
  filtrife: [
    {id:"T", label: "Tutti", capacita: [], module: undefined},
    {id:"R", label: "RMA", capacita: [], module: undefined},
    {id:"A",label:"Attivita", capacita: [], module: undefined},
    {id:"C",label:"Attivita Cliente", capacita: [], module: undefined},
    {id:"S", label: "Scadenze", capacita: [], module: undefined},
    {id:"F",label:"Fasi", capacita: [], module: undefined},
    {id:"L", label: "Lezioni", capacita: [], module: undefined},
    {id:"B",label:"Spese", capacita: [], module: undefined},
    {id:"I", label: "Fatture", capacita: [], module: undefined}
  ],
  CalendarAddon: {
    managerScadenza : true,
  },
  PartiAddOn: [false],//  categorie associate
  RmaAddOn: {
    taskGenerico: true,
    vociDiCosto: false,
    provaAcquisto:true,
    CategoriaProdotto: true,
    useTasklistInClienti: true,
    TipoAcquisto: false,
    TipoAcquistoSource: "ClientiType",
    Rivenditore:false,
    NumeroDocumento: false,
    DataDocumento: false,
    Ricevuta: false,
    Targhetta: false,
    CircostanzaDifetto: false,
    DescrizioneDifetto: false,
    Difetto: true,
    Telefono: true,
    Garanzia: true,
    EstensioneGaranzia: true,
    Allegati: true,
    Modello: true,
    Matricola: true,
    Brand: true,
    sottoTask: true,
    headersScadenza:[
      {nome: "Titolo", oggetti: ["nome"]},
      {nome: "Responsabile", oggetti: ['managerObject'], type: "object", field:['fullName'] },
      {nome: "Chiuso", oggetti: ['data_fine_effettiva'], inputType: 'datetime-local', type: "boolean"},
    ],
    AzioniScadenza:[

      {nome: '', class:' ',  labelClass:'ml-1 text-orange font-weight-bold', type: 'POST', restRoute: '/rma/segnalazione/prendiincarico', capacita: [], icon:'fas fa-user text-orange', placeholder: 'Prendi in carico', successMex: "Preso in carico", parameter: [{nome:"id", parameter: 'id'}, {nome: 'presaInCarico', value: true}]},
      {nome: '', class:' ',  labelClass:'ml-1 text-danger font-weight-bold', type: 'POST', restRoute: '/rma/segnalazione/delete', preAlert: 'Sei sicuro di voler eliminare questo elemento?', capacita: ['gestione-taskboard'], icon:'fas fa-trash text-danger', placeholder: 'Elimina', successMex: 'Elemento eliminato con successo', parameter: [{nome:"id", parameter: 'id'}]},

    ],
    campiScadenza: [
      {
        modificabile: true,
        nome: 'Responsabile',
        oggetti: ['manager'],
        inputType: 'singleDropdown',
        source: '/anagrafica/persone/list',
        responseHasData: true,
        parameter: [{nome:"page", value: 1}, {nome:"size", value: 200}],
        internalOutput: 'managerObject',
        OnFilter: 'xx',
        OnChange: 'xxy',
        OutputField: 'manager',
        placeholder: 'Seleziona',
        settings: {
          singleSelection: true,
          closeDropDownOnSelection: true,
          singleOutput: true,
          idField: 'id',
          textField: 'fullName',
          searchPlaceholderText: "inserire almeno 3 caratteri",
          allowSearchFilter: true,
          allowRemoteDataSearch: true,
        },
        class: 'col-lg-3',
        visibleifProhibited: true,
      },
      {nome: "Files", type: "Files", url:"/rma/segnalazione/file", modificabile:true, class: 'col-lg-12', OnChange: 'OnFilesChange'},
    ],
    Ritiro: {
      Enabled: false,
      Referente: true,
      Nominativo: true,
      Indirizzo: true,
      Citta: true,
      Cap: true,
      Provincia: true,
      Telefono: true,
      Email: true,
      Chiusura: true,
      Disponibilita: true
    },
    Cliente: {
      Enabled: false,
      Referente: true,
      Nominativo: true,
      Indirizzo: true,
      Citta: true,
      Cap: true,
      Provincia: true,
      Telefono: true,
      Email: true,
      Chiusura: true,
      Disponibilita: true
    },
    Assistenza: {
      Enabled: false,
      Referente: true,
      Nominativo: true,
      Indirizzo: true,
      Citta: true,
      Cap: true,
      Provincia: true,
      Telefono: true,
      Email: true,
      Chiusura: true,
      Disponibilita: true,
      Tecnico: false,
    }
  },
  //#endregion
  //#region Menu
  Menu: [
    {
      title: "Menu",
      class: "has-arrow",
      label: "Menu",
      extralink: true
    },
    {
      module: "Dashboard",
      path: "/dashboard/dashboard",
      title: 'menu.dashboard',
      icon: "mdi mdi-gauge",
      capacita: ["menu-dashboard"]
    },
    {
      module: "Personale",
      path: "/listautenti/personale",
      title:'menu.PersonaleLabel',
      icon: "far fa-user",
      capacita: ["menu-persone"]
    },
    // {
    //   module: "Clienti",
    //   capacita: ["menu-clienti"],
    //   path: "/listautenti/aziende/c",
    //   title: 'Clienti',
    //   icon: "mdi mdi-account-multiple",
    // },
    // {
    //   module: "Clienti",
    //   capacita: ["menu-clienti"],
    //   path: "/listautenti/aziende/f",
    //   title: 'Fornitori',
    //   icon: "mdi mdi-account-multiple",
    // },
    {
      module: "Clienti",
      path: "/listautenti/aziende",
      title:'menu.clienti',
      icon: "mdi mdi-account-multiple",
      capacita: ["menu-clienti"]
    },

    {
      module: "Task",
      path: "task/list",
      title: 'menu.lista',
      icon: "mdi mdi-calendar"
    },

    {
      module: "taskboard",
      path: "task/attivita",
      title: 'menu.taskboard',
      icon: "mdi mdi-calendar"
    },


    {
      module: "Stampe",
      path: "stampe/templates",
      title: "menu.StampeLabel",
      icon: "mdi mdi-printer",
      class: "has-arrow",
      capacita: ["menu-stampa"],
      submenu: [
        {
          path: "stampe/templates",
          title: "Templates",
          capacita: ["gestione-stampa"]
        },
        {
          path: "stampe/marker",
          title: "Marker",
          capacita: ["menu-stampa"]
        }
      ]
    },
    {
      module: "Settings",
      path: "settings/settings",
      title: "menu.SettingsLabel",
      icon: "mdi-settings",
      capacita: ["menu-settings"]
    },
    {
      module: "Settings",
      path: "settings/smtp",
      title: "menu.smtp",
      icon: "mdi-settings",
      capacita: ["menu-settings"]
    },
    {
      module: "Admin",
      path: "listautenti/roles",
      title: "Ruoli",
      icon: "mdi mdi-lock-open-outline",
      capacita: ["menu-ruoli"]
    },
    {
      module: "Ticket",
      path: "ticket/list",
      title: "Tickets",
      icon: "mdi mdi-bookmark-outline",
      capacita: ["menu-ticket"]
    },
  ],
  //#endregion
};
